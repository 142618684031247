<template>
  <div class="order__ckeck-list__option">
    <input type="checkbox" 
      :id="`ckeck_${payment.ddpayOrderId}`" 
      :checked="selectedPaymentOrder?.ddpayOrderId === payment?.ddpayOrderId"
      @change="onSelect"
    />
    <label :for="`ckeck_${payment.ddpayOrderId}`">
      <div class="order__ckeck-list__left">
        <!-- <span class="order__number">{{ $t("Merchant order ID") }}</span> -->
        <span class="order__number"> {{ abbreviation }}</span>
        <span class="order__s  erial-number"> {{ payment.merchantOrderId }}</span>
      </div>
      <div class="order__ckeck-list__right">
        <time>
          {{ moment.unix(payment.createdAt).format("YYYY/MM/DD HH:mm") }}</time
        >
        <span class="order__total">
          {{
            formatCurrency(
              payment.orderInfo?.amount,
              payment.orderInfo?.currencySettings
            )
          }}
        </span>
      </div>
    </label>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import { formatCurrency } from "@/utils/utils";
import moment from "moment-timezone";
export default {
  name: "PaymentOrder",
  props: {
    payment: Object,
    default: function () {
      return {};
    },
  },
  data() {
    return {
      formatCurrency,
      moment,
    };
  },
  computed: {
    // ...mapState({
    //   isLoggedIn: (state) => state.isLoggedIn,
    // }),
    ...mapGetters({
      selectedPaymentOrder: "paymentList/getSelectedPaymentOrder",
    }),
    abbreviation() { // 顯示最後三碼
      return this.payment?.merchantOrderId ? this.payment.merchantOrderId.substr(this.payment.merchantOrderId.length - 3) : '';
    }
  },
  methods: {
    onSelect() {
      if (this.selectedPaymentOrder?.ddpayOrderId !== this.payment?.ddpayOrderId) {
        this.$store.commit('paymentList/setSelectedPaymentOrder', this.payment);
      } else {
        this.$store.commit('paymentList/setSelectedPaymentOrder', null);
      }
    }
  },
};
</script>
