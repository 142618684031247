<template>
  <section class="head">
    <div class="bg"></div>
    <span> {{shopName}} </span>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';

export default {
  name: 'LogoHeader',
  props: {
    showGoBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      shopName: 'getShopName',
      amount: 'getAmount',
    }),
    // ...mapState({
    //   isLoggedIn: (state) => state.isLoggedIn,
    //   user: (state) => state.user,
    //   order: (state) => state.orderInfo,
    //   // amount: (state) => state.amount,
    // }),
    // token() {
    //   return this.$route.params.token;
    // },
    // storeName() {
    //   return this.order.shopId;
    // },
  },
  methods: {},
};
</script>
