<template>
  <div class="price">
    <!-- Loading -->
    <!-- <div class="loading"><div><img src="img/favicon.png"><div></div></div></div> -->
    <!-- Main -->
    <LogoHeader />
    <!-- Footer -->
    <section class="foot">
      <button @click="onSubmit" :class="{ disabled: price === 0 }">
        <span> {{ $t('Confirm the amount') }} </span>
      </button>
    </section>
    <section class="main">
      <span class="title"> {{ $t('Enter the amount') }} </span>
      <span class="cost"> {{ formatCurrency(price, currencySettings) }}</span>
      <div class="keyboard">
        <div>
          <a
            v-for="value in [7, 8, 9]"
            :key="'col1' + value"
            @click="onKeyPressed(value)"
            @touchstart.prevent="onKeyPressed(value)"
          >
            {{ value }}
          </a>
        </div>
        <div>
          <a
            v-for="value in [4, 5, 6]"
            :key="'col2' + value"
            @click="onKeyPressed(value)"
            @touchstart.prevent="onKeyPressed(value)"
          >
            {{ value }}
          </a>
        </div>
        <div>
          <a
            v-for="value in [1, 2, 3]"
            :key="'col3' + value"
            @click="onKeyPressed(value)"
            @touchstart.prevent="onKeyPressed(value)"
          >
            {{ value }}
          </a>
        </div>
        <div>
          <a @click="onKeyPressed('clear')" @touchstart.prevent="onKeyPressed('clear')"> {{ $t('Clear')}} </a>
          <a @click="onKeyPressed(0)" @touchstart.prevent="onKeyPressed(0)">
            0
          </a>
          <a @click="onKeyPressed('del')" @touchstart.prevent="onKeyPressed('del')">
            <font-awesome-icon
              icon="fa-solid fa-delete-left"
              size="lg"
              class="minor"
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import LogoHeader from '@/components/LogoHeader.vue';
import { formatCurrency } from '@/utils/utils';

export default {
  name: 'Payment',
  components: { LogoHeader },
  data() {
    return {
      formatCurrency,
      price: 0,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      user: (state) => state.user,
      order: (state) => state.orderInfo,
      amount: (state) => state.amount,
    }),
    token() {
      return this.$route.params.token;
    },
    shopName() {
      return this.order.shopName;
    },
    currencySettings() {
      return this.order?.orderInfo?.currencySettings || null;
    },
    // amount() {
    //   return (
    //     (this.order && this.order.orderInfo && this.order.orderInfo.amount) || 0
    //   );
    // },
  },
  mounted() {
    // 從別頁按回上頁時需要
    this.price = this.amount;
  },
  methods: {
    onKeyPressed(_key) {
      if (_key === 'del') {
        this.price = Math.floor(this.price / 10);
      } else if (_key === 'clear') {
        this.price = 0;
      } else if (_key >= 0) {
        this.price = this.price * 10 + _key;
      }
    },
    onSubmit() {
      this.$store.commit('updateAmount', this.price);
      // reset 點券
      this.$store.commit("updateSelectedPoint", null);
      this.$store.commit("updateDiscounts", []);
      this.$store.commit("updateSelectedCoupon", null);
      this.$router.push({ name: 'Order', params: { token: this.token }, query: this.$route.query });
    },
  },
};
</script>
